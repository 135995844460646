import React, { useState } from 'react';
import classNames from 'classnames';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { UploadFileButton } from '../Button/Button';
import { useIntl } from 'react-intl';

import css from './UploadFile.module.css';

const IconFile = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={css.fileIcon}>
    <path
      d="M19 9V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V6.2C5 5.07989 5 4.51984 5.21799 4.09202C5.40973 3.71569 5.71569 3.40973 6.09202 3.21799C6.51984 3 7.0799 3 8.2 3H13M19 9L13 3M19 9H14C13.4477 9 13 8.55228 13 8V3"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconDownloadFile = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 11V17M12 17L10 15M12 17L14 15M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconUploadFile = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={css.uploadFileIcon}
  >
    <path
      d="M12 18V12M12 12L14 14M12 12L10 14M13 3H8.2C7.0799 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V17.8C5 18.9201 5 19.4802 5.21799 19.908C5.40973 20.2843 5.71569 20.5903 6.09202 20.782C6.51984 21 7.0799 21 8.2 21H15.8C16.9201 21 17.4802 21 17.908 20.782C18.2843 20.5903 18.5903 20.2843 18.782 19.908C19 19.4802 19 18.9201 19 17.8V9M13 3L19 9M13 3V7.4C13 7.96005 13 8.24008 13.109 8.45399C13.2049 8.64215 13.3578 8.79513 13.546 8.89101C13.7599 9 14.0399 9 14.6 9H19"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const IconDeleteFile = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={css.deleteFileIcon}
  >
    <path
      d="M13.5 3H12H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H11M13.5 3L19 8.625M13.5 3V7.625C13.5 8.17728 13.9477 8.625 14.5 8.625H19M19 8.625V11.8125"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 16L17.5 18.5M20 21L17.5 18.5M17.5 18.5L20 16M17.5 18.5L15 21"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UploadedFile = props => {
  const { setError, handleDeleteTempFile, hasBeenSent, intl } = props;

  const { ownerId, fileName } = props.file;

  const fileNamePath = encodeURIComponent(`${ownerId}/${fileName}`);
  return (
    <div
      // If className is provided, use it, otherwise use the default one
      className={classNames(props.className || css.ownMessageContent, css.fileContainer)}
      onClick={() => {
        // If has been sent
        if (hasBeenSent) {
          // Get the signed URL for the file
          fetch(
            `${
              process.env.REACT_APP_MARKETPLACE_ROOT_URL
            }/api/s3/getDownloadUrl?file=${fileNamePath}&expirationTime=${60}`
          )
            .then(res => res.json())
            .then(response => {
              if ('downloadUrl' in response) {
                // Download the file
                const link = document.createElement('a');
                link.href = response.downloadUrl;
                link.download = fileName;
                link.click();
              } else {
                console.error('Unable to download file with server response', e);
                setError(intl.formatMessage({ id: 'UploadFile.downloadFileError' }, { fileName }));
              }
            })
            .catch(e => {
              console.error('Unable to download file', e);
              setError(intl.formatMessage({ id: 'UploadFile.downloadFileError' }, { fileName }));
            });
        } else {
          fetch(
            `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/api/s3/deleteFile?file=${fileNamePath}`,
            {
              method: 'DELETE',
            }
          )
            .then(response => {
              handleDeleteTempFile && handleDeleteTempFile(response.ok, fileName);
            })
            .catch(e => {
              console.error('Unable to delete file', e);
              setError(intl.formatMessage({ id: 'UploadFile.deleteFileError' }, { fileName }));
            });
        }
      }}
    >
      {fileName}
      <IconFile />
      {props.hasBeenSent ? <IconDownloadFile /> : <IconDeleteFile />}
    </div>
  );
};

const handleDeleteTempFile = (setUploadedFilePaths, setError, intl, isOk, fileName) => {
  if (isOk) {
    setUploadedFilePaths(prev => prev.filter(file => file.fileName !== fileName));
  } else {
    console.error('Unable to delete file');
    setError(intl.formatMessage({ id: 'UploadFile.deleteFileError' }, { fileName }));
  }
};

export const UploadedFiles = props => {
  const { uploadedFilePaths, setUploadedFilePaths, setError, className } = props;

  const intl = useIntl();

  if (uploadedFilePaths.length === 0) return null;

  return (
    <div className={classNames(css.tempFilesContainer, className)}>
      {uploadedFilePaths.map((file, index) => (
        <UploadedFile
          file={file}
          key={index}
          handleDeleteTempFile={(isOk, fileName) =>
            handleDeleteTempFile(setUploadedFilePaths, setError, intl, isOk, fileName)
          }
          setError={setError}
          intl={intl}
        />
      ))}
    </div>
  );
};

export const UploadedFilesWithButton = props => {
  const [error, setError] = useState(null);
  const { uploadedFilePaths, setUploadedFilePaths, ownerId, className } = props;

  return (
    <div className={classNames(css.uploadedFilesWrapper, className)}>
      <div
        className={classNames(css.uploadedFilesContainer, {
          // Used to align the button to flex-end when no files are uploaded
          [css.noFiles]: uploadedFilePaths.length === 0,
        })}
      >
        <UploadedFiles
          uploadedFilePaths={uploadedFilePaths}
          setUploadedFilePaths={setUploadedFilePaths}
          setError={setError}
        />
        <UploadFileButton
          ownerId={ownerId}
          setUploadedFilePaths={setUploadedFilePaths}
          setError={setError}
        />
      </div>
      <ErrorMessage error={error} className={css.errorMessage} />
    </div>
  );
};

export const MessageFormUploadedFilesWithButton = props => {
  const [error, setError] = useState(null);
  const {
    uploadedFilePaths,
    setUploadedFilePaths,
    ownerId,
    className,
    buttonProps,
    children,
    additionalError,
  } = props;

  return (
    <div className={classNames(css.uploadedFilesWrapper, className)}>
      <div
        className={classNames(css.uploadedFilesContainer, {
          // Used to align the button to flex-end when no files are uploaded
          [css.noFiles]: uploadedFilePaths.length === 0,
        })}
      >
        <UploadedFiles
          uploadedFilePaths={uploadedFilePaths}
          setUploadedFilePaths={setUploadedFilePaths}
          setError={setError}
        />
        <div className={css.buttonContainer}>
          <UploadFileButton
            {...buttonProps}
            ownerId={ownerId}
            setUploadedFilePaths={setUploadedFilePaths}
            setError={setError}
          />
          {children}
        </div>
      </div>
      <div className={css.errorContainer}>
        <ErrorMessage error={error} className={css.errorMessage} />
        <ErrorMessage error={additionalError} className={css.errorMessage} />
      </div>
    </div>
  );
};
